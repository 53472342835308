import React, { useState } from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Star from "../images/star.svg";
import { Link } from "gatsby";
import { MdArrowForwardIos } from "react-icons/md";

const RelatedInsurances = ({ title, insurance1, insurance2, insurance3 }) => {
	const linkGradientStyle = {
		background: "linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		display: "inline", // Apply the gradient to text
		textDecoration: "none", // Optional: removes underline from links
		fontWeight: "bold", // Optional: for better visibility
	};
	const cardStyle = {
		padding: "1px", // This creates space for the gradient border
		borderRadius: "13px",
		background: "linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)", // Gradient border
	};

	const contentStyle = {
		borderRadius: "12px",
		maxWidth: "100%",
		background: "white", // Content background (can be 'white' or any color)
		minHeight: "100%",
		// Or 'p-4' if using Bootstrap classes
	};

	return (
		<Container className="py-5 py-lg-7 ">
			<Row className="justify-content-between  g-5 ">
				<Col>
					<h2 className="mb-5 text-center">{title}</h2>
					<Row className="g-4">
						{insurance1 !== null && insurance1?.title !== "" && (
							<Col lg={4}>
								<div className="h-100" style={cardStyle}>
									<div
										className=" h-100 d-flex align-items-center justify-content-center"
										style={contentStyle}
									>
										<div className="d-block p-4">
											<h3 className="gradient-text ">{insurance1?.title}</h3>
											<p>{insurance1?.insurancePageFields?.cardDescription}</p>
											<Link
												to={`/insurance/${insurance1?.slug}`}
												style={linkGradientStyle}
											>
												Read more <MdArrowForwardIos />
											</Link>
										</div>
									</div>
								</div>
							</Col>
						)}
						{insurance2 !== null && insurance2?.title !== "" && (
							<Col lg={4}>
								<div className="h-100" style={cardStyle}>
									<div
										className=" h-100 d-flex align-items-center justify-content-center"
										style={contentStyle}
									>
										<div className="d-block p-4">
											<h3 className="gradient-text ">{insurance2?.title}</h3>
											<p>{insurance2?.insurancePageFields?.cardDescription}</p>
											<Link
												to={`/insurance/${insurance2?.slug}`}
												style={linkGradientStyle}
											>
												Read more <MdArrowForwardIos />
											</Link>
										</div>
									</div>
								</div>
							</Col>
						)}
						{insurance3 !== null && insurance3?.title !== "" && (
							<Col lg={4}>
								<div className="h-100" style={cardStyle}>
									<div
										className=" h-100 d-flex align-items-center justify-content-center"
										style={contentStyle}
									>
										<div className="d-block p-4">
											<h3 className="gradient-text ">{insurance3?.title}</h3>
											<p>{insurance3?.insurancePageFields?.cardDescription}</p>
											<Link
												to={`/insurance/${insurance3?.slug}`}
												style={linkGradientStyle}
											>
												Read more <MdArrowForwardIos />
											</Link>
										</div>
									</div>
								</div>
							</Col>
						)}
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default RelatedInsurances;
