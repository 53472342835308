import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BrokerSectionHome = ({
	title,
	link1,
	link2,
	link3,
	link4,
	link5,
	imageOneData,
	imageTwoData,
	imageThreeData,
	imageFourData,
	imageFiveData,
	altTexts, // assuming this is an array of 5 alt texts
}) => {
	// Retrieve images using the 'getImage' helper function
	const imageOne = getImage(imageOneData);
	const imageTwo = getImage(imageTwoData);
	const imageThree = getImage(imageThreeData);
	const imageFour = getImage(imageFourData);
	const imageFive = getImage(imageFiveData);

	return (
		<Container fluid className="px-0 my-5">
			<Row className="justify-content-center align-items-center">
				<Col className="text-center">
					<h2 className="fs-3 mb-4">{title}</h2>
				</Col>
			</Row>
			<Row className="justify-content-center align-items-center">
				<Col
					style={{ borderRight: "1px solid #C4D6E4" }}
					xs={6}
					md={true}
					className="text-center py-3"
				>
					<a target="_blank" rel="noreferrer" href={link1}>
						<GatsbyImage
							style={{ maxWidth: "130px" }}
							image={imageOne}
							alt={altTexts[0]}
						/>
					</a>
				</Col>
				<Col
					style={{ borderRight: "1px solid #C4D6E4" }}
					xs={6}
					md={true}
					className="text-center py-3"
				>
					<a target="_blank" rel="noreferrer" href={link2}>
						<GatsbyImage
							style={{ maxWidth: "130px" }}
							image={imageTwo}
							alt={altTexts[1]}
						/>
					</a>
				</Col>
				<Col
					style={{ borderRight: "1px solid #C4D6E4" }}
					xs={6}
					md={true}
					className="text-center py-3"
				>
					<a target="_blank" rel="noreferrer" href={link3}>
						<GatsbyImage
							style={{ maxWidth: "130px" }}
							image={imageThree}
							alt={altTexts[2]}
						/>
					</a>
				</Col>
				<Col
					style={{ borderRight: "1px solid #C4D6E4" }}
					xs={6}
					md={true}
					className="text-center py-3"
				>
					<a target="_blank" rel="noreferrer" href={link4}>
						<GatsbyImage
							style={{ maxWidth: "130px" }}
							image={imageFour}
							alt={altTexts[3]}
						/>
					</a>
				</Col>
				<Col xs={6} md={true} className="text-center py-3">
					<a target="_blank" rel="noreferrer" href={link5}>
						<GatsbyImage
							style={{ maxWidth: "130px" }}
							image={imageFive}
							alt={altTexts[4]}
						/>
					</a>
				</Col>
			</Row>
		</Container>
	);
};

export default BrokerSectionHome;
