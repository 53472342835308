import React, { useState } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Alert,
  Form,
  Link,
} from "react-bootstrap";
import SafeHtmlParser from "./safe-html-parser";

const GetQuoteBanner = ({ title, description, buttonText, buttonLink }) => {
  return (
    <section
      style={{ background: "linear-gradient(#001249, #000B2D)" }}
      className="py-5 py-lg-7"
    >
      <Container className="newsletter-subscribe-container">
        <Row className="justify-content-center align-items-center">
          <Col md={6} className=" text-white">
            <SafeHtmlParser htmlContent={title} />
            <p className=" text-white mt-3">{description}</p>
          </Col>
          <Col className="text-end" md={6}>
            <div className="d-flex align-items-end align-items-xl-start align-items-xl-center justify-content-end flex-column-reverse flex-xl-row">
              <div className="d-flex align-items-center w-100 w-sm-auto">
                <span className="fw-bold small-text me-2 me-xl-3 mb-0 text-white">
                  Only takes 3 minutes
                </span>
                <StaticImage
                  src={"../images/arrow-right.png"}
                  alt="Get Quote"
                  layout="fixed"
                  height={18}
                  // width={100}
                  className="me-3 d-none d-xl-block"
                />
                <StaticImage
                  src={"../images/arrow-mobile-white.png"}
                  alt="Get Quote"
                  layout="fixed"
                  className="d-block d-xl-none mt-1"
                  height={70}
                />
              </div>
              <Button
                size="lg"
                className=" w-100 w-sm-75 w-lg-auto mt-4 mt-md-0 gradient-button px-3 px-xl-5 py-3 text-uppercase"
                href={buttonLink}
              >
                {buttonText}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GetQuoteBanner;
