import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const WhyUse = ({ title, description, items }) => {
  return (
    <Container className="  ">
      <Row className="justify-content-center text-center mb-2">
        <Col lg={7} className="info-heading">
          {title}
          <p className="mt-4">
            {" "}
            {description}
          </p>
        </Col>
      </Row>
      {items.length > 0 && (
        <div className="py-3">
          <Row>
            {items.map((item) => (
              <Col className="p-2 p-sm-3 text-center" xs={6} lg={3}>
                <div
                  className="p-3 p-sm-4 h-100"
                  style={{ boxShadow: "0px 3.75px 12px 0px #080F340F" }}
                >
                  {item.icon && (
                    <GatsbyImage
                      image={
                        item.icon?.localFile.childImageSharp?.gatsbyImageData
                      }
                      className="mb-4"
                      alt={item.icon?.altText}
                      style={{ height: "48px", width: "48px" }}
                      imgStyle={{ width: "48px", height: "48px" }}
                    />
                  )}
                  <h6 className="mb-2">{item.title}</h6>
                  <p>{parse(item.description)}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default WhyUse;
