import React, { useState } from "react";
import { Container, Carousel, Row, Col, Button } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Star from "../images/star.svg";
import "./../styles/components/best-quote-insurance-section.scss";
import SafeHtmlParser from "./safe-html-parser";

const BestQuoteInsuranceSection = ({
	title,
	description,
	buttonLink,
	buttonText,
	imageOne,
	imageTwo,
	altText1,
	altText2,
}) => {
	return (
		<Container className=" py-5 py-lg-7">
			<Row className="justify-content-between gx-xl-9  g-5 gy-lg-0 ">
				<Col className="d-md-none" lg={6}>
					<div
						style={{
							overflow: "hidden",
							zIndex: 0,
							borderRadius: "15px",
							boxShadow: "0px 9.648px 11.94px 0px rgba(0, 69, 136, 0.12)",
						}}
						className=" "
					>
						<GatsbyImage
							className="w-100 h-100"
							image={imageTwo}
							alt={altText2}
						/>
					</div>
				</Col>
				<Col
					lg={6}
					className="position-relative d-none d-md-flex justify-content-center align-items-center "
				>
					<Row>
						<Col className="position-relative" xs={4}>
							<div
								style={{
									overflow: "hidden",
									zIndex: 1,
									borderRadius: "15px",
									boxShadow: "0px 9.648px 11.94px 0px rgba(0, 69, 136, 0.12)",
								}}
								className="position-absolute end--20 bottom-10 mw-100"
							>
								<GatsbyImage
									className="w-100 h-100"
									image={imageOne}
									alt={altText1}
								/>
							</div>
						</Col>
						<Col className="position-relative" xs={8}>
							<div
								style={{
									overflow: "hidden",
									zIndex: 0,
									borderRadius: "15px",
									boxShadow: "0px 9.648px 11.94px 0px rgba(0, 69, 136, 0.12)",
								}}
								className="position-relative start--10  mw-100 "
							>
								<GatsbyImage
									className="w-100 h-100"
									image={imageTwo}
									alt={altText2}
								/>
							</div>
						</Col>
					</Row>

					{/* <Row className='h-100 my-lg-6 '>
            <Col
              md={3}
              className=' position-relative'
              style={{ zIndex: 1, minHeight: '100%' }}
            >
              <div
                className='position-absolute end--50 bottom-30'
                style={{
                  borderRadius: '24px',
                  overflow: 'hidden',
                  boxShadow: '0px 16px 19.8px 0px rgba(0, 69, 136, 0.12)',
                }}
              >
                <GatsbyImage
                  className='w-100'
                  image={imageOne}
                  alt={altText1}
                />
              </div>
            </Col>
            <Col md={6} className='' style={{ zIndex: 0 }}>
              <div
                style={{
                  borderRadius: '24px',
                  overflow: 'hidden',
                  boxShadow: '0px 16px 19.8px 0px rgba(0, 69, 136, 0.12)',
                }}
              >
                <GatsbyImage
                  className='w-100'
                  image={imageTwo}
                  alt={altText2}
                />
              </div>
            </Col>
			
          </Row> */}
				</Col>
				<Col lg={6} className="position-relative ">
					<SafeHtmlParser htmlContent={title} />
					<SafeHtmlParser htmlContent={description} />

					<Button
						size="lg"
						className="gradient-button w-100 w-md-auto px-5 py-3 mt-3 text-uppercase"
						href={buttonLink}
					>
						{buttonText}
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default BestQuoteInsuranceSection;
