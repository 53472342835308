import React, { useState } from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Star from "../images/star.svg";
import SafeHtmlParser from "./safe-html-parser";

const AboutInsuranceSection = ({
	title,
	content,
	featuresTitle,
	Feature1Icon,
	Feature1IconAlt,
	Feature1title,
	Feature1description,
	Feature2Icon,
	Feature2IconAlt,
	Feature2title,
	Feature2description,
	Feature3Icon,
	Feature3IconAlt,
	Feature3title,
	Feature3description,
	Feature4Icon,
	Feature4IconAlt,
	Feature4title,
	Feature4description,
}) => {
	return (
		<Container className=" ">
			<Row className="justify-content-between  g-5 ">
				<Col lg={7}>
					<SafeHtmlParser htmlContent={title} />
					<SafeHtmlParser htmlContent={content} />
				</Col>
				<Col lg={4} className="position-relative">
					<h3 className="fs-2 text-center mb-5">{featuresTitle}</h3>
					<div
						style={{
							borderRadius: "12px",
							boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06) ",
						}}
						className="text-center bg-white pt-5 p-4"
					>
						<GatsbyImage
							style={{ maxWidth: "40px" }}
							image={Feature1Icon}
							alt={Feature1IconAlt}
						/>
						<h4 className="my-3">{Feature1title}</h4>
						<p>{Feature1description}</p>
					</div>
					<div
						style={{
							borderRadius: "12px",
							boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06) ",
						}}
						className="text-center bg-white mt-4 pt-5 p-4"
					>
						<GatsbyImage
							style={{ maxWidth: "40px" }}
							image={Feature2Icon}
							alt={Feature2IconAlt}
						/>
						<h4 className="my-3">{Feature2title}</h4>
						<p>{Feature2description}</p>
					</div>
					<div
						style={{
							borderRadius: "12px",
							boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06) ",
						}}
						className="text-center bg-white mt-4 pt-5 p-4"
					>
						<GatsbyImage
							style={{ maxWidth: "40px" }}
							image={Feature3Icon}
							alt={Feature3IconAlt}
						/>
						<h4 className="my-3">{Feature3title}</h4>
						<p>{Feature3description}</p>
					</div>
					<div
						style={{
							borderRadius: "12px",
							boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06) ",
						}}
						className="text-center bg-white mt-4 pt-5 p-4"
					>
						<GatsbyImage
							style={{ maxWidth: "40px" }}
							image={Feature4Icon}
							alt={Feature4IconAlt}
						/>
						<h4 className="my-3">{Feature4title}</h4>
						<p>{Feature4description}</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default AboutInsuranceSection;
